<template>
  <div class="checkin-rule">
    <a-button icon="plus-circle" @click="showAddRule" class="checkin-rule-add">添加规则</a-button>
    <div class="checkin-rule-table" v-show="list.length > 0">
      <a-table
        size="middle"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        bordered
        :row-key="(record) => record.id"
      >
        <template v-slot:action="text, record, index">
          <div>
            <a class="txt-btn" @click.stop="showDetail(record, index)">查看</a>
            <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
            <a class="txt-btn danger" @click.stop="delRow(record, index)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getDayText } from "./rule";
export default {
  name: "CheckinRule",
  data() {
    return {
      list: [],
      columns: [
        { title: "规则名称", dataIndex: "name" },
        {
          title: "考勤规则",
          customRender: (text) => {
            let type = text.type;
            if (type == 2) {
              return "自由上下班";
            } else {
              let ruleTimeDetail = text.ruleTimeDetail;
              return ruleTimeDetail?.map((item) => {
                return (
                  <div>
                    <div>{getDayText(item.days)}</div>
                    <div>
                      上班 {item.start_time} — 下班 {item.end_time}
                    </div>
                  </div>
                );
              });
            }
          },
        },
        {
          title: "打卡地点",
          dataIndex: "location_config",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <span>{text?.[0]?.location.address}</span>;
            } else {
              return "-";
            }
          },
        },
        { title: "操作", key: "action", width: 130, scopedSlots: { customRender: "action" } },
      ],
    };
  },
  created() {
    this.getRules();
  },
  methods: {
    showAddRule() {
      this.$router.push({ name: "CheckinRuleAdd" });
    },
    getRules() {
      const url = "/admin/checkin-rule?pageSize=1000&expand=ruleTimeDetail";
      this.$axios(url).then((res) => {
        this.list = res.data;
      });
    },
    showDetail(record) {
      this.$router.push({ name: "CheckinRuleDetail", params: { id: record.id } });
    },
    editRow(record) {
      this.$router.push({ name: "CheckinRuleDetail", params: { id: record.id }, query: { edit: 1 } });
    },
    delRow(record) {
      this.$confirm({
        title: "提示",
        content: `确定删除此规则吗？`,
        onOk: () => {
          this.$axios({
            url: `/admin/checkin-rule/${record.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.getRules();
          });
        },
      });
    },
  },
};
</script>

<style lang="less">
.checkin-rule-table {
  margin: 10px 0;
}
.txt-btn:not(:first-of-type) {
  margin-left: 10px;
}
</style>
