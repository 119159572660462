<template>
  <div class="flex-grow scroll-area">
    <div class="content md">
      <div class="checkin-setting">
        <div class="set-info-item">
          <div class="set-info-label">可见范围</div>
          <div class="set-info-ctx">
            <select-book
              :edit="edit"
              multiple
              type="book"
              v-model="original_visible_ids"
              @change="visibleChange"
            ></select-book>
          </div>
        </div>
        <div class="set-info-item">
          <div class="set-info-label">管理员</div>
          <div class="set-info-ctx">
            <select-book
              :edit="edit"
              multiple
              type="book"
              v-model="original_admin_ids"
              @change="adminChange"
            ></select-book>
          </div>
        </div>
      </div>
      <div class="gap"></div>
      <div class="checkin-setting">
        <div class="set-info-item">
          <div class="set-info-label">上下班打卡</div>
          <div class="set-info-ctx flex-grow">
            <checkin-rule></checkin-rule>
          </div>
        </div>
        <!-- <div class="set-info-item">
        <div class="set-info-label">外出打卡</div>
        <div class="set-info-ctx"></div>
      </div> -->
        <div class="set-info-item">
          <div class="set-info-label">考勤记录</div>
          <div class="set-info-ctx">
            <a-button @click="goRecord">查看记录</a-button>
          </div>
        </div>
        <div class="set-info-item">
          <div class="set-info-label">今日祝福</div>
          <div class="set-info-ctx">
            <a-button @click="goWish">查看祝福</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckinRule from "./CheckinRule";
export default {
  name: "CheckinHome",
  components: { CheckinRule },
  data() {
    return {
      edit: true,
      info: null,
      original_visible_ids: null,
      original_admin_ids: null,
    };
  },
  created() {
    this.getTemp();
  },
  methods: {
    getTemp() {
      let detail = this.$store.getters.detail;
      if (detail && detail.type == "temp" && detail.obj.type == 25) {
        this.dealInfo(detail.obj);
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      let url = `/admin/apply-temp?filter[type]=25`;
      this.$axios(url).then((res) => {
        let data = res.data;
        if (data?.[0]) {
          this.dealInfo(data?.[0]);
        } else {
          this.$message("未找到打卡模板，请联系管理员");
        }
      });
    },
    dealInfo(info) {
      if (info.original_visible_ids) {
        this.original_visible_ids = JSON.parse(info.original_visible_ids);
      }
      if (info.original_admin_ids) {
        this.original_admin_ids = JSON.parse(info.original_admin_ids);
      }
      this.info = info;
    },
    visibleChange(original_visible_ids) {
      let visible_ids = {
        uids: [],
        dept: [],
        tag: [],
      };
      original_visible_ids.forEach((item) => {
        if (item.type == "department") {
          visible_ids.dept.push(item.id);
        } else if (item.type == "user") {
          visible_ids.uids.push(item.id);
        } else if (item.type == "tag") {
          visible_ids.tag.push(item.id);
        }
      });
      this.patchApplyTemp({ original_visible_ids: JSON.stringify(original_visible_ids), visible_ids });
    },
    adminChange(original_admin_ids) {
      let admin_ids = {
        uids: [],
        dept: [],
        tag: [],
      };
      original_admin_ids.forEach((item) => {
        if (item.type == "department") {
          admin_ids.dept.push(item.id);
        } else if (item.type == "user") {
          admin_ids.uids.push(item.id);
        } else if (item.type == "tag") {
          admin_ids.tag.push(item.id);
        }
      });
      this.patchApplyTemp({ original_admin_ids: JSON.stringify(original_admin_ids), admin_ids });
    },
    patchApplyTemp(data) {
      this.$axios({
        url: `/admin/apply-temp/${this.info.id}`,
        method: "PATCH",
        data,
      }).then(() => {
        this.$message.success("修改成功");
      });
    },
    goWish() {
      this.$router.push({ name: "CheckinWish" });
    },
    goRecord() {
      this.$router.push({ name: "CheckinRecord" });
    },
  },
};
</script>

<style lang="less" scoped></style>
