import {getDoubleText, getKeyTitle} from "../../../common/js/tool";

export const ruleTypeList = [
    { key: 1, title: '固定上下班', desc: '所有人按照相同时间打卡，适用于办公室坐班，如早九晚五' },
    { key: 2, title: '自由上下班', desc: '无时间限制，可随时打卡只统计旷工' },
]

export const lateMinutesOptions = new Array(73).fill(0).map((item, i) => {
    const m = i * 5;
    return {
        key: m,
        title: `${m}分钟`
    }
});

// 默认打卡时间表单值
export const defaultTimeForm = {
    day: [1,2,3,4,5],
    time: ["09:00", "18:00"],
}

// 默认弹性上下班表单值

export const defaultLateConfig = {
    cd: 10,
    zt: 10,
    zdzz: 30,
    wdwz: 30,
}

export const weekDayOptions = [
    { label: "星期一", value: 1, title: "周一" },
    { label: "星期二", value: 2, title: "周二" },
    { label: "星期三", value: 3, title: "周三" },
    { label: "星期四", value: 4, title: "周四" },
    { label: "星期五", value: 5, title: "周五" },
    { label: "星期六", value: 6, title: "周六" },
    { label: "星期日", value: 0, title: "周日" },
]

// 默认补卡设置
export const default_complement_config = {
    timeLimit: -1,
    count: -1,
    dateLimit: -1,
}

// 打卡方式
export const methodOptions = [
    { label: "手机", value: 3 },
    { label: "手机+考勤机", value: 1 },
    { label: "考勤机", value: 2 },
]

// 生效时间
export const effectTimeOptions = [
    { label: "明日生效", value: 1, content: "修改内容将于明日4:00生效" },
    { label: "立即生效", value: 2, content: "修改内容将立即生效，同时今日已打卡记录将会失效，确认“立即生效”？" },
]

// 打卡状态
export const checkinStatusOptions = [
    { label: "正常", value: 1 },
    { label: "异常", value: 2 },
]

// 身份
export const typeOptions = [
    { label: "僧人", value: 1 },
    { label: "职工", value: 2 },
    { label: "志工", value: 3 },
]

// 允许补卡时间限制
export const getTimeOptions = () => {
    const timeOptions = new Array(180).fill().map((item, index) => ({ label: ++index + "天", value: index }));
    timeOptions.unshift({ label: "无限制", value: -1 });
    return timeOptions;
}
export const timeOptions = getTimeOptions();

// 每月允许补卡次数
export const getCountOptions = () => {
    const countOptions = new Array(99).fill().map((item, index) => ({ label: ++index + "次", value: index }));
    countOptions.unshift({ label: "无限制", value: -1 });
    return countOptions;
}
export const countOptions = getCountOptions();

// 每月补卡截止日期
export const getDateOptions = () => {
    const dateOptions = new Array(28).fill().map((item, index) => ({ label: "下月" + ++index + "日", value: index }));
    dateOptions.unshift({ label: "不设置", value: -1 });
    return dateOptions;
}
export const dateOptions = getDateOptions();

export const getDayText = function (days) {
    days = days.split(",");
    return days.map(d => getKeyTitle(weekDayOptions, d, "value")).join("、")
}

export const getHourText = function (hour) {
    return hour >= 24 ? `次日${getDoubleText(hour - 24)}` : getDoubleText(hour);
}

export const getTimeArray = function (str) {
    return str ? str.split(":").map(Number) : []
}

export const getHourTimeText = function (time) {
    time = getTimeArray(time);
    return time.length > 0 ? `${getHourText(time[0])}:${getDoubleText(time[1])}` : "";
}

export const getTimeMinutes = function (t) {
    let res = 0;
    if(t) {
        const time = t.split(":").map(Number);
        res = time[0] * 60 + time[1];
    }
    return res;
}

export const getCheckinStatusText = function(status) {
    let target = checkinStatusOptions.find(item => item.value == status)
    return target?.label || "-";
}

export const getTypeText = function(status) {
    let target = typeOptions.find(item => item.value == status)
    return target?.label || "-";
}
